<template>
  <div class="page1">
    <!--主体内容-->
    <div class="content bgFFF">
      <h2 class="title">
        <div class="title_icon"></div>
        添加监控设备
      </h2>
      <!--搜索条件区域-->
      <div class="Wrapper" @keydown.enter.prevent="submitData">
        <el-form
          label-position="right"
          label-width="100px"
          :model="formInline"
          style="width: 300px; float: left"
          :rules="rules"
          :ref="'form'"
        >
          <el-form-item :label="$t('searchModule.Equipment_name')" prop="monitorEquipmentName">
            <el-input
              :maxlength="20"
              v-model.trim="formInline.monitorEquipmentName"
              placeholder="请输入内容"
            ></el-input>
          </el-form-item>
          <el-form-item :label="$t('searchModule.Equipment_type')" prop="monitorEquipmentType">
            <el-select v-model.trim="formInline.monitorEquipmentType">
              <el-option label="HK-海康" value="1"></el-option>
              <el-option label="DH-大华" value="2"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('searchModule.Merchant_affiliation')" prop="operationId">
            <el-select
              v-model.trim="formInline.operationId"
              @change="operationChange"
              filterable
              size="15"
            >
              <el-option
                :label="value.operationName"
                :value="value.operationId"
                :key="value.operationId"
                v-for="value in tenantList"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('searchModule.Belonging_parking_lot')" prop="parkId">
            <auto-complete
              ref="select"
              :operationIdRequired="true"
              :operationId="formInline.operationId"
              slaveRelations="0,2"
              @valueChange="completeValue"
            ></auto-complete>
          </el-form-item>
          <el-form-item class="lnglat" label="经纬度" prop="lnglat">
            <el-input v-model.trim="formInline.lnglat"  placeholder="请输入经纬度"></el-input
            >
            <!-- <i class="el-icon-map-location" @click="openMap" style="position: absolute; top: 13px; left: 175px"></i> -->
            <el-button type="primary" class="map1" @click="openMap">地图选点</el-button>
          </el-form-item>
          <el-form-item :label="$t('searchModule.address')" prop="address">
            <el-input
              :maxlength="50"
              type="textarea"
              v-model.trim="formInline.address"
              placeholder="输入车场详细地址"
            ></el-input>
          </el-form-item>
          <el-form-item label="网络模式" prop="netType">
            <el-select v-model="formInline.netType" placeholder="请选择">
              <el-option label="固网" :value="0"></el-option>
              <el-option label="无线网络" :value="1"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="IP地址" prop="ipAddress" v-if="formInline.netType == 0">
            <el-input
              :maxlength="15"
              v-model.trim="formInline.ipAddress"
              placeholder="请输入内容"
            ></el-input>
          </el-form-item>
          <el-form-item label="端口号" prop="ipPort" v-if="formInline.netType == 0">
            <el-input
              :maxlength="10"
              v-model.trim="formInline.ipPort"
              placeholder="请输入内容"
            ></el-input>
          </el-form-item>
          <el-form-item label="视频端口号" prop="monitorPort" v-if="formInline.netType == 0">
            <el-input
              :maxlength="10"
              v-model.trim="formInline.monitorPort"
              placeholder="请输入内容"
            ></el-input>
          </el-form-item>
          <el-form-item label="用户名" prop="account" v-if="formInline.netType == 0">
            <el-input
              :maxlength="20"
              v-model.trim="formInline.account"
              placeholder="请输入内容"
            ></el-input>
          </el-form-item>
          <el-form-item label="密码" prop="password" v-if="formInline.netType == 0">
            <el-input
              :maxlength="20"
              v-model.trim="formInline.password"
              placeholder="请输入内容"
            ></el-input>
          </el-form-item>
          <el-form-item label="设备编码：" prop="channelCode" v-if="formInline.netType == 1">
            <el-input
              v-model="formInline.channelCode"
              placeholder="请输入设备编码"
              oninput="value=value.replace(/[^\d]/g,'')"
              maxlength="32"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div style="text-align: left; margin-left: 8.5%">
        <el-button type="primary" @click="submitData" style="width: 88px">{{
          $t("button.preservation")
        }}</el-button>
        <el-button type="" @click="$router.go(-1)" style="width: 88px">取消</el-button>
      </div>
    </div>
    <map-con ref="mapConpent" @mapCancel="mapCancel" @mapSure="mapSure"></map-con>
  </div>
</template>
<script>
import autoComplete from "@/components/autocomplete";
import mapCon from "@/components/map";
export default {
  name: "hello",
  data() {
    let validateIpPort = (rule, value, callback) => {
      let reg = /^[0-9]+.?[0-9]*$/;
      if (reg.test(value)) {
        this.formInline.ipPort = parseInt(value);
        if (this.formInline.ipPort >= 65535) {
          callback("端口号应小于65535");
        } else {
          callback();
        }
      } else {
        callback("端口号请输入纯数字");
      }
    };
    let validateMonitorPort = (rule, value, callback) => {
      let reg = /^[0-9]+.?[0-9]*$/;
      if (reg.test(value)) {
        this.formInline.monitorPort = parseInt(value);
        if (this.formInline.monitorPort >= 65535) {
          callback("视频端口号应小于65535");
        } else {
          callback();
        }
      } else {
        callback("视频端口号应为纯数字");
      }
    };

    let validateLnglat = (rule, value, callback) => {
      if (!value.includes(',')) {
        callback("请输入正确的经纬度格式");
        // this.$message.error('请输入正确的经纬度')
      } else {
      let arr = value.split(',') 
      let countO = String(arr[0]).length - String(arr[0]).indexOf(".") - 1
      let count1 = String(arr[1]).length - String(arr[1]).indexOf(".") - 1
      if (arr[0] > 180 || arr[0] < -180 || arr[1] > 90 || arr[1] < -90) {
        // this.$message.error('请输入正确的经纬度')
        callback("请输入正确的经纬度格式");
      } else if (!arr[0] || !arr[1]) {
        // this.$message.error('请输入正确的经纬度')
        callback("请输入正确的经纬度");
      } else if (countO > 6 || count1 > 6) {
        // this.$message.error('请输入正确的经纬度')
        callback("请输入正确的经纬度格式");
      } else {
        callback();
      }
      }
    }
    return { 
      flag: false, // 是否能够提交
      parkPage: 1,
      parkName: "",
      dis: false,
      pickerOptions1: {
        disabledDate(time) {
          return time.getTime() < Date.now();
        },
      },
      parkData: [],
      parkTypeAll: [],
      parkList: "",
      areaList: [],
      isEdit: false,
      loading: false,
      startTime: "",
      tenantList: [],
      formInline: {
        lnglat: "",
        monitorEquipmentType: "",
        parkId: "",
        operationId: "",
        monitorEquipmentName: "",
        address: "",
        latitude: "",
        longitude: "",
        ipAddress: "",
        ipPort: "",
        monitorPort: "",
        account: "",
        password: "",
        netType: "",
        channelCode: "",
      },
      rules: {
        monitorEquipmentType: [{ required: true, message: "必填", trigger: ["blur", "change"] }],
        parkId: [
          {
            required: true,
            message: "必填",
            trigger: ["blur", "change"],
          },
        ],
        operationId: [
          {
            required: true,
            message: "必填",
            trigger: ["blur", "change"],
          },
        ],
        monitorEquipmentName: [
          {
            required: true,
            message: "必填",
            trigger: "blur",
          },
        ],
        address: [
          {
            required: true,
            message: "必填",
            trigger: "blur",
          },
        ],
        latitude: [
          {
            required: true,
            message: "必填",
            trigger: "blur",
          },
        ],
        lnglat: [
          {
            required: true,
            message: "请输入经纬度坐标",
            trigger: "blur",
          },
          { validator: validateLnglat, trigger: "blur" },
        ],
        ipAddress: [
          {
            required: true,
            message: "必填",
            trigger: "blur",
          },
        ],
        ipPort: [
          {
            required: true,
            message: "必填",
            trigger: "blur",
          },
          { validator: validateIpPort, trigger: "blur" },
        ],
        monitorPort: [
          {
            required: true,
            message: "必填",
            trigger: "blur",
          },
          { validator: validateMonitorPort, trigger: "blur" },
        ],
        account: [
          {
            required: true,
            message: "必填",
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: "必填",
            trigger: "blur",
          },
        ],
        netType: [
          {
            required: true,
            message: "必填",
            trigger: "blur",
          },
        ],
        channelCode: [
          {
            required: true,
            message: "必填",
            trigger: "blur",
          },
        ],
      },
    };
  },
  watch: {
    // 'formInline.latitude': {
    //   handler(val) {
    //     console.log(val, '经纬度');
    //   },
    //   deep: true,
    //   immediate: true
    // }
  },
  methods: {
    operationChange() {
      this.$refs.select.setValue();
    },
    completeValue(item) {
      this.formInline.parkId = item;
      if (!item) return;
      this.getParkDetail(item);
    },
    /* 商户名称的下拉框 */
    getTenantList() {
      this.$axios.get("/acb/2.0/operation/nameList").then((res) => {
        this.loading = false;
        if (res.state == 0) {
          this.tenantList = res.value;
        } else {
          this.$alert(res.desc, this.$t("pop_up.Tips"), {
            confirmButtonText: this.$t("pop_up.Determine"),
          });
        }
      });
    },
    openMap() {
      this.$refs.mapConpent.openMap(this.formInline.lnglat);
    },
    mapCancel() {
      this.$refs.mapConpent.mapClose();
      // this.formInline.parkAddress = '';
      // this.lnglat = '';
    },
    mapSure(opt) {
      this.formInline.latitude = opt.temLat * 1000000;
      this.formInline.longitude = opt.temLng * 1000000;
      this.formInline.address = opt.parkAddress;
      this.formInline.lnglat = opt.hasSelect;
      this.$refs.mapConpent.mapClose();
    },
    getParkDetail(parkId) {
      let url = "/acb/2.0/park/" + parkId;
      this.$axios.get(url).then((res) => {
        if (res.state == 0) {
          let data = res.value;
          this.formInline.lnglat = data.longitude / 1000000 + "," + data.latitude / 1000000;
          this.formInline.latitude = data.latitude;
          this.formInline.longitude = data.longitude;
          this.formInline.address = data.parkAddress;
          // this.treeData = this.sortArea(res.value);
        } else {
          this.$alert(res.desc, this.$t("pop_up.Tips"), {
            confirmButtonText: this.$t("pop_up.Determine"),
          });
        }
      });
    },

    submitData() {
      this.$refs["form"].validate((valid) => {
        if (this.formInline.ipPort >= 65535) {
          this.$alert("端口号应小于65535", this.$t("pop_up.Tips"), {
            confirmButtonText: this.$t("pop_up.Determine"),
          });
          return;
        }
        if (valid) {
          this.formInline.latitude = this.formInline.lnglat.split(',')[1] * 1000000
          this.formInline.longitude = this.formInline.lnglat.split(',')[0] * 1000000
          this.formInline.ipPort = this.formInline.ipPort ? parseInt(this.formInline.ipPort) : "";
          this.formInline.monitorPort = this.formInline.monitorPort
            ? parseInt(this.formInline.monitorPort)
            : "";
          if (this.formInline.ipPort >= 65535 || this.formInline.monitorPort >= 65535) {
            this.$alert("端口号应小于65535", this.$t("pop_up.Tips"), {
              confirmButtonText: this.$t("pop_up.Determine"),
            });
            return;
          }
          if (this.formInline.netType == 1) {
            this.formInline.ipAddress = "";
            this.formInline.ipPort = "";
            this.formInline.monitorPort = "";
            this.formInline.account = "";
            this.formInline.password = "";
          } else {
            this.formInline.channelCode = "";
          }
          // else if (!(typeof this.formInline.ipPort == "number")) {
          //   this.$alert("端口号应为纯数字", this.$t('pop_up.Tips'), {
          //     confirmButtonText: this.$t('pop_up.Determine')
          //   });
          //   return;
          // }
          delete this.formInline.lnglat
          this.$axios
            .post("/acb/2.0/monitorEquipment/add", {
              data: this.formInline,
            })
            .then((res) => {
              if (res.state == 0) {
                this.$alert("操作成功！", this.$t("pop_up.Tips"), {
                  confirmButtonText: this.$t("pop_up.Determine"),
                }).then(() => {
                  this.$router.go(-1);
                });
              } else {
                this.$alert(res.desc, this.$t("pop_up.Tips"), {
                  confirmButtonText: this.$t("pop_up.Determine"),
                });
              }
            });
        } else {
          return false;
        }
      });
    },
  },
  components: {
    mapCon,
    autoComplete,
  },
  created() {
    if (this.$route.query.parentId) {
      this.isEdit = true;
    }
    this.getTenantList();
  },
  mounted() {},
  computed: {},
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
::v-deep .el-form-item.lnglat {
  width: 500px;
  .el-input {
    width: 200px;
  }
  .el-input__inner {
    width: 200px
  }
}
.Wrapper
  overflow hidden
.breadcrumb
  height 35px
.content
  overflow hidden
  border 1px solid #C0CCDA
  border-radius 4px
  padding 20px
.uploadPic
  float left
  margin-left 150px
.map1 {
  margin-left: 8px;
  padding: 11px 15px;
}
</style>
<style>
.avatar-uploader2 .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader2 .el-upload:hover {
  border-color: #0f6eff;
}
.avatar-uploader-icon2 {
  font-size: 28px;
  color: #8c939d;
  width: 295px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar2 {
  width: 295px;
  height: 178px;
  display: block;
}
</style>
